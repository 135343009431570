import { API_PATHS, HTTP_REQUEST_TYPE } from '../../constants/api';

import { sendHttpRequest } from './api';


const getMyIdTravelLoginUrl = async () => {
  const [error, resp] = await sendHttpRequest({
    apiTitle: 'Get myIDTravel Login Url',
    method: HTTP_REQUEST_TYPE.get,
    path: API_PATHS.login,
  });
  return {
    ...resp,
    error,
  };

};

export { getMyIdTravelLoginUrl };
