/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';

import { Box, Link, Typography } from '@mui/material';

import { LoginStatus } from './constants/constants';

import en from './translations/en';

import { loginRequest } from './authConfig';
import { getMyIdTravelLoginUrl } from './services/api/login';

const App = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;
  const [loginStatus, setLoginStatus] = useState(LoginStatus.NOT_STARTED);
  const [url, setUrl] = useState('');

  useEffect(() => {
    const itemKey = 'msal.interaction.status';
    if (sessionStorage.getItem(itemKey)) {
      sessionStorage.removeItem(itemKey);
    }

    if (isAuthenticated) {
      getMyIdLoginTravelUrlAction();
    } else {
      instance.loginRedirect(loginRequest);
    }
  }, []);

  const getMyIdLoginTravelUrlAction = async () => {
    try {
      const response = await getMyIdTravelLoginUrl();

      if (response && response.url) {
        setLoginStatus(LoginStatus.SUCCESS);
        setUrl(response.url);
        window.location.href = response.url;
      } else {
        setLoginStatus(LoginStatus.FAILED);
      }
    } catch (error) {
      setLoginStatus(LoginStatus.FAILED);
    }
  };

  return (
    <Box sx={{ mt: 2, mb: 4, ml: 2 }}>
      {loginStatus === LoginStatus.SUCCESS ? (
        <Typography
          sx={{
            typography: { xs: 'body_2_regular', sm: 'body_1_regular' },
          }}
        >
          {en.success.redirectingToMyIDTravelPage}
          <Link href={url} sx={{ cursor: 'pointer' }} style={{ marginRight: 5, marginLeft: 5 }}>
            <Typography variant="headline_medium">here.</Typography>
          </Link>
        </Typography>
      ) : loginStatus === LoginStatus.FAILED ? (
        <Typography
          sx={{
            typography: { xs: 'body_2_regular', sm: 'body_1_regular' },
          }}
        >
          {en.failed.pleaseClick}
          <Link href="/" sx={{ cursor: 'pointer' }} style={{ marginRight: 5, marginLeft: 5 }}>
            <Typography variant="headline_medium">here</Typography>
          </Link>
          {en.failed.toAccessMyIDTravelPage}
        </Typography>
      ) : loginStatus === LoginStatus.NOT_STARTED ? (
        <Typography
          sx={{
            typography: { xs: 'body_2_regular', sm: 'body_1_regular' },
          }}
        >
          {en.notStarted.pendingToMyIDTravelPage}
        </Typography>
      ) : (
        ''
      )}
    </Box>
  );
};

export default App;
