const AXIOS_ERR_CODE_TIMEOUT = 'ECONNABORTED';

const HTTP_REQUEST_TYPE = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

const API_PATHS = {
  login: '/myidtravel-backend/v1/login',
};

export { AXIOS_ERR_CODE_TIMEOUT, HTTP_REQUEST_TYPE, API_PATHS };
