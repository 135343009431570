import React from 'react';
import ReactDOM from 'react-dom/client';

import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';

import App from './App';
import { loginRequest } from './authConfig';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { msalInstance } from './services/msalInstance';

const ErrorComponent = () => {
  sessionStorage.clear();
  return <p>Error while logging in, please refresh the page to login again.</p>;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
        authenticationRequest={loginRequest}
      >
        <App />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
