interface IApiRespObj {
  errors: {
    status: string;
    title: string;
    detail: any;
    requestId?: string;
  };
}

class ServerError extends Error {
  status: string;
  title: string;
  detail: string;
  requestId?: string;

  constructor(responseObj: IApiRespObj) {
    super();

    const { errors } = responseObj || {};
    const { status, title, detail, requestId } = errors;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError);
    }

    this.status = status;
    this.title = title;
    this.detail = detail;
    this.requestId = requestId;
  }

  toJson() {
    return {
      status: this.status,
      title: this.title,
      detail: this.detail,
    };
  }
}

export default ServerError;
