import axios from 'axios';
import qs from 'qs';

import { AXIOS_ERR_CODE_TIMEOUT, HTTP_REQUEST_TYPE } from '../../constants/api';

import { getNewAccessToken } from '../../authConfig';
import ServerError from './serverError';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    config: {
      baseURL: string;
      travelRequirementsLink: string;
      azureClientId: string;
      azureAuthority: string;
      cxDeployEnv: string;
      redirectUri: string;
    };
  }
}

const instance = axios.create({
  baseURL: window.config.baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(async (config) => {
  const accessToken = await getNewAccessToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    delete config.headers.Authorization;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.resolve({ status: 200, data: error.message });
    }

    return Promise.reject(error);
  },
);

const getHttpRequestResp = async ({ method, path, payload }: { method: string; path: string; payload?: any }) => {
  let response;
  try {
    if (method === HTTP_REQUEST_TYPE.get) {
      response = await instance.get(`${path}?${payload ? qs.stringify(payload) : ''}`);
    } else if (method === HTTP_REQUEST_TYPE.post) {
      response = await instance.post(path, payload);
    }
  } catch (err: any) {
    if (err.code === AXIOS_ERR_CODE_TIMEOUT) {
      response = {
        data: {
          errors: {
            status: 'ERROR',
            title: 'Timeout',
            detail: 'Timeout',
          },
        },
      };
    } else if (err.response) {
      response = err.response;
      console.error('error response', err.response.data.errors);
    } else if (err.message === 'Network Error') {
      response = {
        data: {
          errors: {
            status: 'ERROR',
            title: 'Network Error',
            detail: 'Network Error',
          },
        },
      };
      console.log('Network Error', err);
    } else {
      console.log('error message', err.message);
      response = {
        data: {
          errors: {
            status: 'ERROR',
            title: 'Error',
            detail: err.message,
            requestId: err.requestId,
          },
        },
      };
    }
  }

  const { status: httpStatus, data: result } = response || {};

  if (httpStatus >= 200 && httpStatus < 300) {
    return result;
  }

  throw new ServerError(result);
};

/**
 * axios http request wrapper
 * @return
 *  [err] when there is error
 *  [, resp] when request was successful
 */
const sendHttpRequest = ({
  method,
  path,
  payload,
  hideErrorDialog = false,
}: {
  apiTitle?: string;
  method: string;
  path: string;
  payload?: any;
  needLoading?: boolean;
  hideErrorDialog?: boolean;
}) => {
  return getHttpRequestResp({
    method,
    path,
    payload,
  })
    .then((data) => [null, data])
    .catch((err) => {
      if (hideErrorDialog) {
        return [err];
      }

      return [err.toJson()];
    })
    .finally();
};

export { sendHttpRequest };
