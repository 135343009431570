// TODO:
// all the frontend display label stored here, can enhance with i18n in the future if need support multiple language

const en = {
  success: {
    redirectingToMyIDTravelPage:
      'Redirecting you to myIDTravel, please wait. If you are not being automatically redirected, please click',
  },
  failed: {
    pleaseClick: 'Please click',
    toAccessMyIDTravelPage: 'to access myIDTravel',
  },
  notStarted: {
    pendingToMyIDTravelPage: 'Redirecting you to myIDTravel, please wait.',
  },
};

export default en;
